import React, {useEffect, useRef} from 'react';
import {ServiceTextContainer, SportsServicesWrapper} from "./sports-services.style";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {Title} from "../home-about/home-about.style";
import {List, ListItem} from "../values/values.style";
import {gsap, Power3, ScrollTrigger} from "gsap/all";

const SportsServices = () => {
    let sportsList = useRef(null);

    useEffect(() => {
        const sportsAboutImage = document.querySelector('.sports-service .gatsby-image-wrapper');

        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();

        gsap.from(sportsAboutImage, {
            scrollTrigger: {
                trigger: sportsAboutImage,
                start: 'top center',
            }, duration: 1, opacity: 0, x: 120, delay: .5, ease: Power3.easeInOut})

        gsap.from(sportsList.children, {
            scrollTrigger: {
                trigger: sportsList,
                start: 'top bottom',
            }, duration: .8, delay: .5, opacity: 0, y: 20, stagger: .4, ease: Power3.easeIn})
    })


    const data = useStaticQuery(graphql`
      query SportsServiceQuery {
      image: file(relativePath: {eq: "footballers.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
          }
        }
      }
    }
 `)
    return (
        <SportsServicesWrapper className='sports-service'>
            <Img fluid={data.image.childImageSharp.fluid}/>
            <ServiceTextContainer>
                <Title style={{color: 'white', borderColor: 'white'}}>Services</Title>
                <List ref={el => sportsList = el}>
                    <ListItem> Representation – Career Management including contract negotiations, transfers and mentoring.</ListItem>
                    <ListItem> Brokering – Intermediary Services</ListItem>
                    <ListItem>Brand Management through our network of associates</ListItem>
                    <ListItem>Legal Advice through our network of reputable law firms</ListItem>
                    <ListItem>Access to network of high calibre coaches</ListItem>
                </List>
            </ServiceTextContainer>
        </SportsServicesWrapper>
    );
};

export default SportsServices;

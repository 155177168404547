import styled from "styled-components";


export const LandingSportsWrapper = styled.div`
  height: calc(50vh - 90px);
  position: relative;
  margin-top: 90px;
  filter: grayscale(70%);
  -webkit-filter: grayscale(70%);
  .gatsby-image-wrapper{
  height: 100% !important;
  &::before{
      content: '';
      display: inline-block;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,0.55);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
  }
  }
  >div:last-of-type{
  position: absolute;
  top: calc(50% - 47.5px);
  left: calc(50% - 299.367px);
  color: white;
  border-color: white;
  z-index: 2;
  }
  
  @media only screen and (max-width: 600px){
  >div:last-of-type{
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 168.75px);
    max-width: 90%;
    font-size: 1rem;
  }
}
`;
import React, {useEffect, useRef} from 'react';
import {SportsTeamWrapper, TextArea} from "./sports-team.style";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {Paragraph, Title} from "../home-about/home-about.style";
import {gsap, ScrollTrigger} from "gsap/all";
import {paragraphAnim} from "../home-about/home-about.component";

const SportsTeam = () => {

    let sportsTeamPara = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(sportsTeamPara);
    })

    const data = useStaticQuery(graphql`
      query SportsTeamQuery {
      image: file(relativePath: {eq: "sports1.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
          }
        }
      }
    }
 `)
    return (
        <SportsTeamWrapper>
            <Img fluid={data.image.childImageSharp.fluid}/>
            <TextArea>
                <Title style={{color: 'white', borderColor: 'white'}}>Our Team</Title>
                <Paragraph ref={el => sportsTeamPara = el}>We have a team of GFA accredited Intermediaries and Scouts; some with professional footballing
                    backgrounds as ex-players that can fully understand our clientele and provide clear and effective
                    advice and support through their wealth of knowledge and experience.</Paragraph>
            </TextArea>
        </SportsTeamWrapper>
    );
};

export default SportsTeam;

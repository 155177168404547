import styled from "styled-components";
import {palette} from "../../utils/elements";
import {SectionWrapper} from "../home-about/home-about.style";


export const SportsTeamWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0 5vw;
  margin-top: 100px;
  >div{
  height: auto;
  width: 100%;
  }
  >div:first-of-type{
  min-height: 400px;
  }
  @media only screen and (min-width: 600px){
  flex-direction: row;
  >div{
  width: 50%;
  }
  }
`;

export const TextArea = styled.div`
  background-color: ${palette.byzantium};
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
  color: white;
  }
`;
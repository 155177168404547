import styled from "styled-components";
import {SectionWrapperTwo} from "../sports-services/sports-services.style";
import {typeScale} from "../../utils/elements";

export const SportsPartnerWrapper = styled(SectionWrapperTwo)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
  @media only screen and (max-width: 600px){
  >div:first-of-type{
  font-size: ${typeScale.paragraph};
  }
  }
   p{
  max-width: 700px;
  }
  @media only screen and (min-width: 900px){
    padding-top: 300px;
  }
`;
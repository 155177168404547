import React from 'react';
import {LandingSportsWrapper} from "./landing.style";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {Title} from "../home-about/home-about.style";

const LandingSports = () => {
    const data = useStaticQuery(graphql`
  query LandingSportsQuery {
  image: file(relativePath: {eq: "sports2.jpg"}) {
    id
    childImageSharp {
      fluid(maxWidth: 5000) {
      ...GatsbyImageSharpFluid
      }
    }
  }
}
  `)
    return (
        <LandingSportsWrapper>
            <Img fluid={data.image.childImageSharp.fluid}/>
            <Title>Sports Management </Title>
        </LandingSportsWrapper>
    );
};

export default LandingSports;

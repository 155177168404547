import styled from "styled-components";
import {SectionWrapper} from "../home-about/home-about.style";
import {palette, typeScale} from "../../utils/elements";

export const SportsServicesWrapper = styled(SectionWrapper)`
  padding: 100px 5vw;
  position: relative;
  display: flex;
  flex-direction: column;
  .gatsby-image-wrapper{
  width: 100%;
  height: 460px;
  }
  @media only screen and (min-width: 900px){
  flex-direction: row;
  .gatsby-image-wrapper{
  width: 50%;
  height: 460px;
  position: absolute;
  top: 70px;
  left: 20px;
  z-index: 1;
  }
  }
`;

export const SectionWrapperTwo = styled.section`
  padding: 100px 5vw 0;
`;

export const ServiceTextContainer = styled.div`
  background-color: black;
  height: 600px;
  width: 100%;
  padding: 50px 20px;
  color: ${palette.grayish};
  ul{
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }
  li{
  margin-top: 15px;
  font-size: ${typeScale.paragraph};
  }
  @media only screen and (min-width: 900px){
  position: absolute;
  right: calc(20px + 5vw);
  width: 50%;
  padding: 50px 60px 50px calc(90px + 5vw);
  }
`;
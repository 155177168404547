import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LandingSports from "../components/landing-sports/landing.component";
import AboutSports from "../components/about-sports/about-sports.component";
import {Container} from "../components/home-about/home-about.style";
import {StyledDivider} from "../components/svg/svg.component";
import SportsServices from "../components/sports-services/sports-services.component";
import SportsPartners from "../components/sports-partners/sports-partners.component";
import SportsTeam from "../components/sports-team/sports-team.component";



const IndexPage = () => {

    return (
        <Layout navScroll={false}>
            <SEO title="Sports"/>
            <LandingSports/>
            <AboutSports/>
            <Container style={{width: '30%', margin: '0 auto'}}><StyledDivider/></Container>
            <SportsServices/>
            <SportsPartners/>
            <Container style={{width: '30%', margin: '50px auto'}}><StyledDivider/></Container>
            <SportsTeam/>
        </Layout>
    )
}



export default IndexPage

import React, {useEffect, useRef} from 'react';
import {Paragraph, SectionWrapper} from "../home-about/home-about.style";
import {gsap, ScrollTrigger} from "gsap/all";
import {paragraphAnim} from "../home-about/home-about.component";

const AboutSports = () => {
    let sportsAboutPara = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(sportsAboutPara);
    })


    return (
        <SectionWrapper style={{padding: '5vw'}}>
            <Paragraph style={{maxWidth: '800px'}} ref={el => sportsAboutPara = el}>Our people are the driving force and we at QUBE PCS Sports Management look only to provide the
                best service possible, utilising the proven expertise of our key agents and associates across the
                globe.</Paragraph>
        </SectionWrapper>
    );
};

export default AboutSports;

import React, {useEffect, useRef} from 'react';
import {Paragraph, Title} from "../home-about/home-about.style";
import {SportsPartnerWrapper} from "./sports-partner.styles";
import {gsap, ScrollTrigger} from "gsap/all";
import {paragraphAnim} from "../home-about/home-about.component";

const SportsPartners = () => {

    let sportsPartnershipPara = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(sportsPartnershipPara);
    })

    return (
        <SportsPartnerWrapper>
            <Title>Partnership</Title>
            <Paragraph ref={el => sportsPartnershipPara = el}>Qube PCS Sports Management arm works jointly with UK firm EQ Sports Management with its UK FA
                registered intermediaries and associates to provide additional service value. Both Directors from each
                respective company have intimate interest in business affairs both in Ghana and the United Kingdom,
                dedicated to the ongoing development and management of player careers.</Paragraph>
        </SportsPartnerWrapper>
    );
};

export default SportsPartners;
